
import * as ipxRuntime$QxWdyQTbsk from '/codebuild/output/src3556145170/src/codestar-connections.ap-southeast-2.amazonaws.com/git-http/674979596220/ap-southeast-2/d2ab412b-fc05-4cc1-a29c-4ec93881895c/carbardev/carbar-web/node_modules/@nuxt/image/dist/runtime/providers/ipx'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {},
  "provider": "ipx",
  "domains": [],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ],
  "quality": 100
}

imageOptions.providers = {
  ['ipx']: { provider: ipxRuntime$QxWdyQTbsk, defaults: {} }
}
        