<template>
	<Headroom :offset="20" @pin="onHeaderPin" @unpin="onHeaderUnpin">
		<header
			class="main-header relative z-50 flex h-20 flex-wrap"
			:class="[
				isMegaMenuActive ? 'megamenu-active' : '',
				isNovatedLeaseStickyActive || isYearEndStickyActive
					? 'mt-12 md:mt-8'
					: '',
			]"
			@mouseleave="hideAllMegaMenu(menu)"
		>
			<div
				class="main-header--nav relative z-20 flex w-full justify-between px-5 duration-200"
				:class="[
					isMegaMenuActive
						? 'bg-gray-50'
						: excludedRoutes[route.path] ||
							  isAfterExcludedRoutes(route.path)
							? 'lg:bg-gray-50'
							: 'bg-white xl:bg-gray-50',
				]"
			>
				<div class="flex w-60 items-center">
					<CarbarLink
						href="/"
						:class="[showMobileMenu ? 'lg:flex' : '']"
						@click="hideMobileMenu"
						@mouseover="hideAllMegaMenu(menu)"
					>
						<IconCarbarLogo />
					</CarbarLink>
				</div>
				<div v-if="showMenu" class="flex-end ml-auto flex">
					<button
						class="hamburger hamburger--spring js-hamburger relative ml-auto flex items-center lg:hidden"
						:class="[showMobileMenu ? 'is-active' : '']"
						type="button"
						@click="toggleMobileMenu"
					>
						<span class="hamburger-box">
							<span class="hamburger-inner"></span>
						</span>
					</button>
				</div>

				<div
					v-if="showMenu"
					class="hidden flex-1 justify-center lg:flex"
				>
					<ul class="m-0 flex list-none gap-x-2">
						<li
							v-for="menuItem in menu"
							:key="menuItem.label"
							class="flex cursor-pointer"
						>
							<template v-if="menuItem.dropdown">
								<ClientOnly>
									<DropdownUserMenu
										:position="'right'"
										:show-caret="false"
										:menu-items="menuItem.dropdown"
										class="flex items-center"
									>
										<template #menu-button>
											{{ menuItem.label }}
										</template>
									</DropdownUserMenu>
								</ClientOnly>
							</template>
							<CarbarLink
								v-else
								class="flex items-center px-4 font-medium delay-75 duration-200"
								:font-size="'text-base'"
								:class="[
									'text-gray-600 hover:text-blue-400',
									menuItem.megaMenuState === 'hidden' ||
									menuItem.megaMenuState === 'hiding'
										? ''
										: 'text-blue-400',
								]"
								:to="getMenuLink(menuItem.link)"
								:underline="false"
								@touchstart.prevent.stop="
									onMenuItemMouseOver(menuItem)
								"
								@mouseover="onMenuItemMouseOver(menuItem)"
								@mouseleave="onMenuItemMouseLeave"
							>
								{{ menuItem.label }}
							</CarbarLink>
						</li>
					</ul>
				</div>

				<div
					v-if="showMenu"
					class="hidden w-60 flex-1 items-center justify-end lg:flex lg:flex-none"
				>
					<div
						class="flex h-full items-center space-x-4 lg:space-x-3"
					>
						<DropdownUserMenu
							v-if="isLoggedIn()"
							:menu-items="userMenu"
							class="group cursor-pointer"
						>
							<template #menu-button>
								<UserAccountIcon
									:is-novated="auUser.isNovated"
								/>
							</template>
							<template #caret>
								<div class="on-open-rotate-90 ml-1">
									<IconArrowDown
										class="text-gray-500 duration-200 group-hover:rotate-90 group-hover:text-blue-400"
									/>
								</div>
							</template>
						</DropdownUserMenu>

						<CarbarLink
							v-if="!isLoggedIn()"
							:href="'/secure/login'"
							:underline="false"
							class="group flex h-fit items-center"
						>
							<IconButton
								v-if="!isLoggedIn()"
								class="hidden lg:flex"
								@click="onLoginClick"
								@mouseover="hideAllMegaMenu(menu)"
							>
								<template #icon>
									<IconUser
										class="h-3 w-3 fill-current text-gray-500 transition-all group-hover:text-orange-400"
									/>
								</template>
								Sign in
							</IconButton>
							<span class="flex lg:hidden">Sign in</span>
						</CarbarLink>
						<CarbarLink
							v-if="!isLoggedIn()"
							:href="`/sign-up#${
								carbar.header.signUpUserType === 'business'
									? 'corporate'
									: 'personal'
							}`"
							:underline="false"
							class="flex h-fit items-center"
						>
							<CarbarButton
								:background-color="'bg-blue-400 hover:bg-sky-600'"
								@mouseover="hideAllMegaMenu(menu)"
							>
								Join for free
							</CarbarButton>
						</CarbarLink>
					</div>
				</div>
			</div>

			<div class="absolute bottom-0 hidden w-full lg:block">
				<PersonalMegaMenu
					:menu="menu"
					:car-count="carbar.vehicleCount.subscription"
				/>
				<BusinessMegaMenu :menu="menu" />
				<SupportMegaMenu :menu="menu" />
			</div>

			<div
				class="mobile-menu absolute inset-0 h-screen max-h-screen w-full overflow-y-auto bg-white pb-10 pt-20 duration-200 lg:hidden"
				:class="[
					showMobileMenu
						? 'translate-x-0 opacity-100'
						: '-translate-x-full opacity-0',
				]"
			>
				<HeaderMobile :menu="menu" />
			</div>
		</header>
	</Headroom>

	<div class="h-20" />

	<StickyHeader v-if="isNovatedLeaseStickyActive" />
	<StickyHeaderYearEnd v-else-if="isYearEndStickyActive" />

	<div
		v-if="isNovatedLeaseStickyActive || isYearEndStickyActive"
		class="h-10"
	/>

	<CarbarCampaigns />
</template>

<script setup>
import CarbarButton from '@/components/buttons/CarbarButton.vue'
import IconButton from '@/components/buttons/IconButton.vue'
import Headroom from '@/vendor/headroom/headroom.vue'
import DropdownUserMenu from '@/components/dropdowns/DropdownUserMenu.vue'
import CarbarLink from '@/components/links/CarbarLink.vue'
import PersonalMegaMenu from '@/components/headers/megamenus/PersonalMegaMenu.vue'
import BusinessMegaMenu from '@/components/headers/megamenus/BusinessMegaMenu.vue'
import SupportMegaMenu from '@/components/headers/megamenus/SupportMegaMenu.vue'
import HeaderMobile from '@/components/headers/HeaderMobile.vue'
import { useCarbarStore } from '@/stores/carbar'
import { useCarbarAuAuthStore } from '@/stores/carbar-au-auth'
import headerModule from '@/modules/Header.mjs'
import IconUser from '@/assets/svg/common/ico-user.svg'
import IconArrowDown from '@/assets/svg/common/ico-arrow_down.svg'
import IconCarbarLogo from '@/assets/svg/logo-carbar-v3.svg'
import CarbarCampaigns from '@/components/campaigns/CarbarCampaigns.vue'
import StickyHeader from '@/components/sticky-header/StickyHeader.vue'
import StickyHeaderYearEnd from '@/components/sticky-header/StickyHeaderYearEnd.vue'
import UserAccountIcon from '@/components/headers/UserAccountIcon.vue'

const route = useRoute()

const emit = defineEmits(['login', 'logout', 'join'])

const carbar = useCarbarStore()
const auUser = useCarbarAuAuthStore()

const excludedRoutes = {
	'/': true,
	'/cars/subscribe': true,
	'/cars/buy/used': true,
	'/terms-and-conditions': true,
	'/privacy-policy': true,
}

const isAfterExcludedRoutes = (path) => {
	const routePaths = Object.keys(excludedRoutes)

	for (const routePath of routePaths) {
		if (path.startsWith(`${routePath}/`)) {
			return true
		}
	}
	return false
}

const { isNovatedLeaseStickyActive } = showNovatedLeaseCampaign()
const { isYearEndStickyActive } = showYearEndSticky()

const { showMegaMenu, hideAllMegaMenu, toggleMobileMenu, hideMobileMenu } =
	headerModule()

const showMobileMenu = computed(() => carbar.header.showMobileMenu)

const props = defineProps({
	showMenu: {
		type: Boolean,
		default: true,
		required: false,
	},
})

const menu = ref([
	{
		key: 'personal-cars',
		label: 'Personal',
		link: '/cars/subscribe',
		current: false,
		showMegaMenu: false,
		hideMegaMenuInterval: null,
		hideMegaMenuTimeout: 500,
		megaMenuState: 'hidden',
		megaMenu: [
			[
				{
					label: 'How it works',
					link: '/car-subscription',
					current: false,
				},
				{
					label: 'Electric car subscription',
					link: '/electric-vehicles',
					current: false,
				},
				{
					label: 'Compare car subscriptions vs leasing',
					link: '/compare-car-subscriptions-vs-leasing',
					current: false,
				},
				{
					label: 'Trade in to subscribe',
					link: '/sell-my-car',
					current: false,
				},
			],
			[
				{
					label: 'Toyota',
					link: `/cars/subscribe/toyota`,
					current: false,
				},
				{
					label: 'Volkswagen',
					link: `/cars/subscribe/volkswagen`,
					current: false,
				},
				{
					label: 'Ford',
					link: `/cars/subscribe/ford`,
					current: false,
				},
				{
					label: 'Mazda',
					link: `/cars/subscribe/mazda`,
					current: false,
				},
				{
					label: 'Hyundai',
					link: `/cars/subscribe/hyundai`,
					current: false,
				},
				{
					label: 'Audi',
					link: `/cars/subscribe/audi`,
					current: false,
				},
			],
			[
				{
					label: 'Toyota RAV4',
					link: `/cars/subscribe/toyota/rav4`,
					current: false,
				},
				{
					label: 'Toyota Kluger',
					link: `/cars/subscribe/toyota/kluger`,
					current: false,
				},
				{
					label: 'Toyota Hilux',
					link: `/cars/subscribe/toyota/hilux`,
					current: false,
				},
				{
					label: 'Ford Ranger',
					link: `/cars/subscribe/ford/ranger`,
					current: false,
				},
				{
					label: 'Toyota Camry',
					link: `/cars/subscribe/toyota/camry`,
					current: false,
				},
				{
					label: 'Kia Carnival',
					link: `/cars/subscribe/kia/carnival`,
					current: false,
				},
			],
			[
				{
					label: 'Utility',
					link: `/cars/subscribe/utility`,
					current: false,
				},
				{
					label: 'SUV',
					link: `/cars/subscribe/suv`,
					current: false,
				},
				{
					label: 'Wagon',
					link: `/cars/subscribe/wagon`,
					current: false,
				},
				{
					label: 'Coupe',
					link: `/cars/subscribe/coupe`,
					current: false,
				},
				{
					label: 'Hatchback',
					link: `/cars/subscribe/hatchback`,
					current: false,
				},
				{
					label: 'Sedan',
					link: `/cars/subscribe/sedan`,
					current: false,
				},
			],
			[
				{
					label: 'Electric',
					link: `/cars/subscribe/electric`,
					current: false,
				},
				{
					label: 'Hybrid',
					link: `/cars/subscribe/hybrid`,
					current: false,
				},
				{
					label: 'Petrol',
					link: `/cars/subscribe/petrol`,
					current: false,
				},
				{
					label: 'Diesel',
					link: `/cars/subscribe/diesel`,
					current: false,
				},
			],
		],
	},
	{
		key: 'business-cars',
		label: 'Business',
		link: '/business',
		current: false,
		showMegaMenu: false,
		hideMegaMenuInterval: null,
		hideMegaMenuTimeout: 500,
		megaMenuState: 'hidden',
		megaMenu: [
			[
				{
					label: 'Novated lease',
					link: '/business/novated-lease',
					current: false,
				},
				{
					label: 'Novated subscription',
					link: '/business/novated-subscription',
					current: false,
				},
				{
					label: 'Novated EV subscription',
					link: '/business/novated-ev-subscription',
					current: false,
				},
				{
					label: 'Fleet subscription',
					link: '/business/fleet-subscription',
					current: false,
				},
				{
					label: 'Employee benefits',
					link: '/business/tools-of-trade/employee-benefits',
					current: false,
				},
			],
			[
				{
					label: 'Toyota',
					link: '/cars/buy/used/toyota',
					current: false,
				},
				{
					label: 'Mazda',
					link: '/cars/buy/used/mazda',
					current: false,
				},
				{
					label: 'Ford',
					link: '/cars/buy/used/ford',
					current: false,
				},
				{
					label: 'Kia',
					link: '/cars/buy/used/kia',
					current: false,
				},
				{
					label: 'Mitsubishi',
					link: '/cars/buy/used/mitsubishi',
					current: false,
				},
				{
					label: 'Hyundai',
					link: '/cars/buy/used/hyundai',
					current: false,
				},
			],
			[
				{
					label: 'Toyota Hilux',
					link: '/cars/buy/used/toyota/hilux',
					current: false,
				},
				{
					label: 'Ford Ranger',
					link: '/cars/buy/used/ford/ranger',
					current: false,
				},
				{
					label: 'Toyota Corolla',
					link: '/cars/buy/used/toyota/corolla',
					current: false,
				},
				{
					label: 'MG ZS',
					link: '/cars/buy/used/mg/zs',
					current: false,
				},
				{
					label: 'Toyota RAV4',
					link: '/cars/buy/used/toyota/rav4',
					current: false,
				},
				{
					label: 'Mazda CX-5',
					link: '/cars/buy/used/mazda/cx-5',
					current: false,
				},
			],
			[
				{
					label: 'Utility',
					link: '/cars/buy/used/utility',
					current: false,
				},
				{
					label: 'SUV',
					link: '/cars/buy/used/suv',
					current: false,
				},
				{
					label: 'Wagon',
					link: '/cars/buy/used/wagon',
					current: false,
				},
				{
					label: 'Coupe',
					link: '/cars/buy/used/coupe',
					current: false,
				},
				{
					label: 'Hatchback',
					link: '/cars/buy/used/hatchback',
					current: false,
				},
				{
					label: 'Sedan',
					link: '/cars/buy/used/sedan',
					current: false,
				},
			],
			[
				{
					label: 'Electric',
					link: '/cars/buy/used/electric',
					current: false,
				},
				{
					label: 'Hybrid',
					link: '/cars/buy/used/hybrid',
					current: false,
				},
				{
					label: 'Petrol',
					link: '/cars/buy/used/petrol',
					current: false,
				},
				{
					label: 'Diesel',
					link: '/cars/buy/used/diesel',
					current: false,
				},
			],
		],
	},
	{
		key: 'support',
		label: 'Support',
		link: 'void',
		current: false,
		showMegaMenu: false,
		hideMegaMenuInterval: null,
		hideMegaMenuTimeout: 500,
		megaMenuState: 'hidden',
		megaMenu: [
			[
				{
					label: 'FAQs',
					link: 'https://support.carbar.com.au',
					current: false,
					target: '_blank',
				},
			],
		],
	},
])

const isMegaMenuActive = computed(
	() =>
		menu.value.filter(
			(item) =>
				item.megaMenuState === 'hiding' ||
				item.megaMenuState === 'shown'
		).length
	// return menu.value.filter((item) => item.showMegaMenu).length
)

let menuTimer = null

const areAllMenusHidden = (menuHidden) =>
	menuHidden.every((item) => item.megaMenuState === 'hidden')

const onMenuItemMouseOver = (menuItem) => {
	clearTimeout(menuTimer)

	if (!areAllMenusHidden(menu.value)) {
		menuTimer = setTimeout(() => {
			hideAllMegaMenu(menu.value)
			showMegaMenu(menuItem)
		}, 200)
	} else {
		hideAllMegaMenu(menu.value)
		menuTimer = setTimeout(() => {
			showMegaMenu(menuItem)
		}, 400)
	}
}

const onMenuItemMouseLeave = () => {
	clearTimeout(menuTimer)
}

const userMenu = ref([
	{
		label: 'Dashboard',
		href: `/account`,
	},
	{
		label: 'Account',
		href: `/account/settings`,
	},
	{
		label: 'Orders',
		href: `/account/orders`,
	},
	{
		label: 'Help & support',
		href: `https://support.carbar.com.au`,
	},
	{
		label: 'Logout',
		onclick: () => {
			signOut()
		},
	},
])

const onHeaderPin = () => {
	// document.body.classList.remove(...['header-unpinned'])
	document.body.classList.add(...['header-pinned'])
}

const onHeaderUnpin = () => {
	document.body.classList.remove(...['header-pinned'])
}

const onLoginClick = () => {
	emit('login')
}

const getMenuLink = (hrefLink) => (hrefLink === 'void' ? '' : hrefLink)
</script>
