import custom_press_release_type_TMQRxYFDpA from "/codebuild/output/src3556145170/src/codestar-connections.ap-southeast-2.amazonaws.com/git-http/674979596220/ap-southeast-2/d2ab412b-fc05-4cc1-a29c-4ec93881895c/carbardev/carbar-web/plugins/custom-press-release-type.js";
import custom_vehicle_type_VllMPpFKwS from "/codebuild/output/src3556145170/src/codestar-connections.ap-southeast-2.amazonaws.com/git-http/674979596220/ap-southeast-2/d2ab412b-fc05-4cc1-a29c-4ec93881895c/carbardev/carbar-web/plugins/custom-vehicle-type.js";
import revive_payload_client_4sVQNw7RlN from "/codebuild/output/src3556145170/src/codestar-connections.ap-southeast-2.amazonaws.com/git-http/674979596220/ap-southeast-2/d2ab412b-fc05-4cc1-a29c-4ec93881895c/carbardev/carbar-web/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/codebuild/output/src3556145170/src/codestar-connections.ap-southeast-2.amazonaws.com/git-http/674979596220/ap-southeast-2/d2ab412b-fc05-4cc1-a29c-4ec93881895c/carbardev/carbar-web/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/codebuild/output/src3556145170/src/codestar-connections.ap-southeast-2.amazonaws.com/git-http/674979596220/ap-southeast-2/d2ab412b-fc05-4cc1-a29c-4ec93881895c/carbardev/carbar-web/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_MwZUzHrRNP from "/codebuild/output/src3556145170/src/codestar-connections.ap-southeast-2.amazonaws.com/git-http/674979596220/ap-southeast-2/d2ab412b-fc05-4cc1-a29c-4ec93881895c/carbardev/carbar-web/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_yVLowv6hDl from "/codebuild/output/src3556145170/src/codestar-connections.ap-southeast-2.amazonaws.com/git-http/674979596220/ap-southeast-2/d2ab412b-fc05-4cc1-a29c-4ec93881895c/carbardev/carbar-web/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/codebuild/output/src3556145170/src/codestar-connections.ap-southeast-2.amazonaws.com/git-http/674979596220/ap-southeast-2/d2ab412b-fc05-4cc1-a29c-4ec93881895c/carbardev/carbar-web/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/codebuild/output/src3556145170/src/codestar-connections.ap-southeast-2.amazonaws.com/git-http/674979596220/ap-southeast-2/d2ab412b-fc05-4cc1-a29c-4ec93881895c/carbardev/carbar-web/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/codebuild/output/src3556145170/src/codestar-connections.ap-southeast-2.amazonaws.com/git-http/674979596220/ap-southeast-2/d2ab412b-fc05-4cc1-a29c-4ec93881895c/carbardev/carbar-web/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_A0OWXRrUgq from "/codebuild/output/src3556145170/src/codestar-connections.ap-southeast-2.amazonaws.com/git-http/674979596220/ap-southeast-2/d2ab412b-fc05-4cc1-a29c-4ec93881895c/carbardev/carbar-web/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/codebuild/output/src3556145170/src/codestar-connections.ap-southeast-2.amazonaws.com/git-http/674979596220/ap-southeast-2/d2ab412b-fc05-4cc1-a29c-4ec93881895c/carbardev/carbar-web/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/codebuild/output/src3556145170/src/codestar-connections.ap-southeast-2.amazonaws.com/git-http/674979596220/ap-southeast-2/d2ab412b-fc05-4cc1-a29c-4ec93881895c/carbardev/carbar-web/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_tbFNToZNim from "/codebuild/output/src3556145170/src/codestar-connections.ap-southeast-2.amazonaws.com/git-http/674979596220/ap-southeast-2/d2ab412b-fc05-4cc1-a29c-4ec93881895c/carbardev/carbar-web/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import plugin_PSbK9A48ZQ from "/codebuild/output/src3556145170/src/codestar-connections.ap-southeast-2.amazonaws.com/git-http/674979596220/ap-southeast-2/d2ab412b-fc05-4cc1-a29c-4ec93881895c/carbardev/carbar-web/node_modules/pinia-plugin-persistedstate/dist/nuxt/runtime/plugin.js";
import sentry_client_shVUlIjFLk from "/codebuild/output/src3556145170/src/codestar-connections.ap-southeast-2.amazonaws.com/git-http/674979596220/ap-southeast-2/d2ab412b-fc05-4cc1-a29c-4ec93881895c/carbardev/carbar-web/plugins/sentry.client.ts";
import tippy_UYJccKXDFD from "/codebuild/output/src3556145170/src/codestar-connections.ap-southeast-2.amazonaws.com/git-http/674979596220/ap-southeast-2/d2ab412b-fc05-4cc1-a29c-4ec93881895c/carbardev/carbar-web/plugins/tippy.js";
import vue_api_query_xdKWJ5HjG4 from "/codebuild/output/src3556145170/src/codestar-connections.ap-southeast-2.amazonaws.com/git-http/674979596220/ap-southeast-2/d2ab412b-fc05-4cc1-a29c-4ec93881895c/carbardev/carbar-web/plugins/vue-api-query.js";
export default [
  custom_press_release_type_TMQRxYFDpA,
  custom_vehicle_type_VllMPpFKwS,
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_MwZUzHrRNP,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_tbFNToZNim,
  plugin_PSbK9A48ZQ,
  sentry_client_shVUlIjFLk,
  tippy_UYJccKXDFD,
  vue_api_query_xdKWJ5HjG4
]